import { createStore } from "vuex";

import auth from "./modules/auth";
import invest from "./modules/invest";
import user from "./modules/user";
import liquidity from "./modules/liquidity";
import map from "./modules/map";
import models from "./modules/models";

// Create store
export default createStore({
  modules: {
    auth,
    invest,
    user,
    liquidity,
    map,
    models,
  },
});
