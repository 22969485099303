module.exports = {
  COMPANY: "SpaceImpact",
  VERSION: "1.0.7",
  POOL_ADDRESS: "0x907f2898b87115A53c8b33C4A390D65c929776c0",
  POOL_ADDRESS_ODD: "0xD2748F97Af748614E306E7185482d0d1afeCc93c",

  // SITE_URL: "https://www.bitfi.ai",
  // SITE_URL: "http://localhost:8080",
  // API_URL: "http://localhost:5000",
  API_URL: "https://api.creaive.ai",
  //Network
  TESTNET: false,
  NETWORK_VERSION: 56, //56 mainnet, 97 testnet
  EXPLORER: "bscscan.com",
  // EXPLORER: "testnet.bscscan.com",

  CHAIN: "BSC",
  CHAIN_FULL_NAME: "Binance Smart Chain (BEP20)",

  ADMIN: [
    "0xc901bc7774587b7052e9b3bfafcc4630ea1607f3",
    "0xa6fbd943c02f025dcc2e3cd3b2ffc6c946f42cac",
    "0xBFa4f1F1722766ceE247239Ad7DA3f5A75bF8e69",
  ],
};
