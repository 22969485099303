import { createApp } from "vue";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";

import "./assets/native-toast.css";
import "./assets/fontawesome/css/all.min.css";
import "./assets/fonts/index.css";
import "./assets/tailwind.css";

import AOS from "aos";
import "aos/dist/aos.css";

import "vue3-carousel/dist/carousel.css";

import vue3GoogleLogin from "vue3-google-login";

import VueSocketIO from "vue-3-socket.io";

import { API_URL } from "./config";

createApp(App)
  .use(AOS.init())
  .use(store)
  .use(router)
  .use(vue3GoogleLogin, {
    clientId:
      "1037171089749-da8ett2cmq036nfd4tsl1bf454r8m2e2.apps.googleusercontent.com",
  })
  .use(
    new VueSocketIO({
      debug: true,
      connection: API_URL,
      vuex: {
        store,
        actionPrefix: "SOCKET_",
        mutationPrefix: "SOCKET_",
      },
    })
  )


  .mount("#app");
