<template>
  <metainfo>
    <template v-slot:title="{ content }">{{
      content ? `${content} | virtual` : `AIvirtual`
    }}</template>
  </metainfo>

  <div id="app">
    <router-view />
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  name: "App",
  data() {
    return {};
  },

  components: {},
  methods: {
    ...mapActions(["logout"]),
  },

  created() {
    // this.logout();
  },
};
</script>
