import { createRouter, createWebHistory } from "vue-router";
import nativeToast from "native-toast";
import store from "../store";

const routes = [
  {
    path: "/",
    component: () => import("../views/public/home.vue"),
  },
  {
    path: "/model",
    component: () => import("../views/public/models.vue"),
  },
  {
    path: "/modelsAI/:modelsAI",
    component: () => import("../views/public/modelsAI.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/modelsImgToImg/:modelsAI",
    component: () => import("../views/public/modelsImgToImg.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/BuppeaSunnivas/:modelsAI",
    component: () => import("../views/public/BuppeaSunnivas.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/promote_buppea/:title",
    component: () => import("../views/public/BuppeaSunnivasV2.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/Fashion/:modelsAI",
    component: () => import("../views/public/Fashion.vue"),
  },
  {
    path: "/movie",
    component: () => import("../views/public/movie.vue"),
  },
  {
    path: "/price",
    component: () => import("../views/public/price.vue"),
  },

  {
    path: "/showCase",
    component: () => import("../views/public/showCase.vue"),
  },

  {
    path: "/remove_background",
    component: () => import("../views/public/remove_background.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/change-interior/:masking_element",
    component: () => import("../views/public/change-interior.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/real_estate",
    component: () => import("../views/public/real_estate.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/superResolution",
    component: () => import("../views/public/superResolution.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/design_Lays",
    component: () => import("../views/public/Design_Lays.vue"),
  },
  {
    path: "/signIn",
    component: () => import("../views/public/SignIn.vue"),
  },

  {
    path: "/lays",
    component: () => import("../views/public/Lays.vue"),
  },

  {
    path: "/create_buppea/:model_id",
    component: () => import("../views/public/create_buppea.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/vote_buppea/:model_id",
    component: () => import("../views/public/vote_buppea.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/voice",
    component: () => import("../views/public/voice.vue"),
  },

  {
    path: "/VoiceConvert",
    component: () => import("../views/public/VoiceConvert.vue"),
  },

  {
    path: "/text_to_speech",
    component: () => import("../views/public/text_to_speech.vue"),
    meta: { requiresAuth: true },
  },
  {
    path: "/text_to_audio",
    component: () => import("../views/public/text_to_audio.vue"),
    meta: { requiresAuth: true },
  },

  {
    path: "/voiceTest",
    component: () => import("../views/public/voiceTest.vue"),
  },
  {
    path: "/videoCreate/:modelsAI",
    component: () => import("../views/public/videoCreate.vue"),
  },

  {
    path: "/discribe_image",
    component: () => import("../views/public/discribe_image"),
    meta: { requiresAuth: true },
  },

  {
    path: "/background_replacer",
    component: () => import("../views/public/background_replacer"),
    meta: { requiresAuth: true },
  },

  {
    path: "/credits",
    component: () => import("../views/auth/user/Credits"),
    meta: { requiresAuth: true },
  },

  {
    path: "/usage",
    component: () => import("../views/auth/user/Account"),
    meta: { requiresAuth: true },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

router.beforeEach((to, from, next) => {
  const currentUser = store.getters.isAuthenticated;
  const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

  if (requiresAuth && !currentUser) {
    next("/");
    nativeToast({
      message: "Please Connect Google Account.",
      position: "top",
      timeout: 5000,
      type: "error",
    });
  } else if (requiresAuth && currentUser) {
    next();
  } else {
    next();
  }
});

export default router;
